import React, { useState, useRef, useEffect } from "react";
import style from './index.module.less'
import { business_mobile, business_pc, } from '@/images/beampro/index'
import useBigScreen from "@/hooks/bigScreen"
import { Layout, SEO } from '@/components/index'
import { useIntl } from "react-intl";
import * as yup from "yup"
import { PulseLoader } from "react-spinners"
import { useGTM ,useGTMInit} from '@/hooks/useGTM.js'

//邮箱验证
const emailSchema = yup
    .string()
    .email()
    .required()

const Index = () => {
    const ifPc = useBigScreen(768);
    const intl = useIntl()
    const [btnChange, setBtnChange] = useState(false)
    const [status, setStatus] = React.useState('edit')
    const [buttonDisabled, setButtondis] = React.useState(false)
    const formSubscribed = intl.formatMessage({ id: "footerpage.subscribed" })
    const formSubmit = intl.formatMessage({ id: "footerpage.subscribe" })
    const [validate, setValidate] = useState(true)
    const [inputValue, setInputValue] = useState('')
    const inputBox = useRef()
    const inputChange = (e) => {
        setInputValue(e.target.value)
    }
    useEffect(() => {
        useGTMInit('business','business')
        window.addEventListener('click', (e) => {
            if (inputBox.current && inputBox.current.contains(e.target)) {
                setBtnChange(true)
            } else {
                setBtnChange(false)
            }
        })
    }, [])
    useEffect(() => {

        if (status == 'sending') {
            setButtondis(true)
        }
        if (status == 'success') {
            setButtondis(true)
            useGTM('subscribe_success','','','business bottom')

        }
    }, [status])
    const submit = async () => {
        if (buttonDisabled) {
            return
        }
        setStatus('sending')
        const isValid = await emailSchema.isValid(inputValue)
        if (!isValid) {
            setValidate(false)
            setStatus('edit')
            setButtondis(false)
            return
        }
        setValidate(true)
        typeof sensors !== "undefined" && sensors.track('module_interaction', {
            custom: {
                module_id: 20077
            }
        });

        var formdata = new FormData();
        formdata.append("email", inputValue);

        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow',
            Headers: {
                'Access-Control-Allow-Origin': "*"
            },
            mode: "no-cors"
        };

        fetch("https://manage.kmail-lists.com/subscriptions/subscribe?a=UacNcq&g=Y8KXCB", requestOptions)
            .then(response => {
                response.text(); setStatus('success')

            })
            .then(result => console.log(result))
            .catch(error => console.log('error', error));
    }

    return (
        <Layout menuBlack={false}>
            <SEO page="XREAL Business" />

            {ifPc?<div className={style.imgBox}><img src={business_pc}/> </div>:<img src={business_mobile} className={style.imgmobile}/>}
            
            <div className={style.content}>
                <div className={style.contentTitle} dangerouslySetInnerHTML={{ __html: `${intl.formatMessage({ id: `beampro_business_text1` })}` }}>

                </div>
                <div className={style.formBoxInput}
                    ref={inputBox}
                >
                    <input
                        placeholder={intl.formatMessage({ id: "email_text" })}
                        onChange={(e) => inputChange(e)}
                        value={inputValue}

                    />

                    <button onClick={submit} style={{ cursor: status == 'success' ? 'auto' : "pointer" }}
                    > {status == 'edit' && formSubmit}
                        {status == 'sending' && <PulseLoader
                            sizeUnit="px"
                            size={6}
                            color={"#fff"}
                        />
                        }
                        {status == 'success' && formSubscribed}
                    </button>

                    {!validate && btnChange && <div className={style.error}>{intl.formatMessage({ id: "form.requireEmail" })}</div>}
                </div>
            </div>

        </Layout>

    )
}

export default Index